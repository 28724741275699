<template>
  <div class="navbar-header expanded">
    <ul
      class="nav navbar-nav h-100 flex-row justify-content-between align-items-center"
    >
      <li class="nav-item w-100">
        <b-link class="navbar-brand mt-0" to="/">
          <span class="brand-logo ">
            <component v-bind:is="'white-logo'" class="white-logo mx-auto"></component>
            <component v-bind:is="'white-full-logo'"  class="white-full-logo mx-auto" style="height: 90px"></component>
          </span>
        </b-link>
      </li>

      <!-- Toggler Button -->
<!--      <li class="nav-item nav-toggle">-->
<!--        <b-link class="nav-link modern-nav-toggle">-->
<!--          <collapse-icon @click="$emit('toggleCollapsedEvent')" />-->
<!--        </b-link>-->
<!--      </li>-->
    </ul>
  </div>
</template>
