{
  "users": {
    "update": "تعديل البيانات",
    "details": "تفاصيل المستخدم",
    "table": {
      "name": "المستخدم",
      "rank": "الرتبه",
      "points": "نقاط الخبرة",
      "phone": "رقم الهاتف",
      "actions": "الاجراءات"
    },
    "block": "حظر المستخدم",
    "unblock": "فك الحظر",
    "created_at": "تاريخ الانشاء",
    "shields": "الدروع",
    "sessions": "جلسات المستخدم",
    "assign-shield": "إسناد درع",
    "email": "البريد الإلكتروني",
    "gender": "الجنس",
    "f": "انثى",
    "m": "ذكر",
    "birth_date": "تاريخ الميلاد",
    "points": "النقاط",
    "locations": "مواقع المستخدم"
  }
}