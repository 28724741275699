import Sortable from "sortablejs";

const createSortable = (el, options, vnode) => {
  return Sortable.create(el, {
    sort: true,
    easing: "cubic-bezier(1, 0, 0, 1)", // Easing for animation. Defaults to null. See https://easings.net/ for examples.
    handle: ".order-handler",  // Drag handle selector within list items
    ...options,
  });
};



export default {
  created(el, binding, vnode) {
    const table = el;
    console.log(el);
    table._sortable = createSortable(
      el,
      binding.value,
      vnode
    );
  },
}; 