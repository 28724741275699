<script setup>
import Navbar from "../components/layouts/Navbar.vue";
import MainMenuHeader from "../components/layouts/MainMenuHeader.vue";
import MainMenuContent from "../components/layouts/MainMenuContent.vue";
import AppContent from "../components/layouts/AppContent.vue";
import { ref, computed, watch, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { WameedFeedbackModal } from "wameed-ui";

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const router = useRouter();

const isVerticalMenuActive = ref(true);
const isVerticalMenuCollapsed = ref(false);

const toggleVerticalMenuActive = () => {
  isVerticalMenuActive.value = !isVerticalMenuActive.value;
};

const toggleCollapsed = () => {
  isVerticalMenuCollapsed.value = !isVerticalMenuCollapsed.value;
  isVerticalMenuActive.value = !isVerticalMenuActive.value;
};
const currentBreakpoint = ref("xl");
watch(currentBreakpoint, (val) => {
  isVerticalMenuActive.value = val === "xl";
});

const resizeHandler = () => {
  if (window.innerWidth >= 1200) currentBreakpoint.value = "xl";
  else if (window.innerWidth >= 992) currentBreakpoint.value = "lg";
  else if (window.innerWidth >= 768) currentBreakpoint.value = "md";
  else if (window.innerWidth >= 576) currentBreakpoint.value = "sm";
  else currentBreakpoint.value = "xs";
};
resizeHandler();
window.addEventListener("resize", resizeHandler);
onUnmounted(() => {
  window.removeEventListener("resize", resizeHandler);
});
const overlayClasses = computed(() => {
  if (currentBreakpoint.value !== "xl" && isVerticalMenuActive.value)
    return "show";
  return null;
});

const layoutClasses = computed(() => {
  const classes = [];
  if (currentBreakpoint.value === "xl") {
    classes.push("vertical-menu-modern");
    classes.push(
      isVerticalMenuCollapsed.value ? "menu-collapsed" : "menu-expanded"
    );
  } 
  else 
  // if (
  //   currentBreakpoint.value === "md" ||
  //   currentBreakpoint.value === "lg"
  // )
   {
    classes.push("vertical-overlay-menu");
    classes.push(isVerticalMenuActive.value ? "menu-open" : "menu-hide");
  }
  //  else {
  //   classes.push("vertical-overlay-menu");
  //   classes.push("menu-collapsed");
  //   classes.push("fticket-mobile-menu "); 
  // }
  // Navbar
  classes.push(`navbar-sticky`);

  return classes;
});

const isMouseHovered = ref(false);

const updateMouseHovered = (val) => {
  isMouseHovered.value = val;
};
const showLogout = ref(false);

const logout = () => {
  //  todo
  cookies.remove("login-data");
  cookies.remove("login-data", "/");
  cookies.remove("login-data", "/ar");
  cookies.remove("login-data", "/en");
  router.push({
    name: "login",
    params: {
      lang: router.currentRoute.value.params.lang,
    },
  });
};
</script>

<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="false ? '1-column' : null"
  >
    <!-- Navbar -->
    <Navbar
    @logout="showLogout = true"
      @toggle-vertical-menu-active-event="toggleVerticalMenuActive"
    />
    <!-- /Navbar -->
    <div
      class="main-menu menu-fixed menu-accordion"
      :class="[
        {
          expanded:
            !isVerticalMenuCollapsed ||
            (isVerticalMenuCollapsed && isMouseHovered),
        },
        'menu-light',
        router.currentRoute.value.name,
      ]"
      @mouseenter="updateMouseHovered(true)"
      @mouseleave="updateMouseHovered(false)"
    >
      <!-- main menu header-->
      <MainMenuHeader @toggle-collapsed-event="toggleCollapsed" />
      <!-- / main menu header-->

      <!-- Shadow -->
      <!-- <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" /> -->

      <!-- main menu content-->
      <MainMenuContent />
      <div class="sidebar-patterns">
        <sidebar-pattern/>
        
      </div>

      <!-- / main menu content-->
    </div>
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- App content-->
    <AppContent :currentBreakpoint="currentBreakpoint"/>
    <!-- / App content-->
    <wameed-feedback-modal
    :title="$t('modal.logout')"
    :subTitle="$t('modal.logout_message')"
    :btnTitle="$t('modal.logout_btn')"
    :cancelTitle="$t('actions.cancel')"
    iconName="logout-icon"
    :visible="showLogout"
    showCloseButton
    variant="error"
    @close="showLogout = false"
    @onClick="logout()"
  />
  </div>
</template>
