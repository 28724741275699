import main from "./main.json";
import common from "./common.json";
import authentication from "./authentication.json";
import layout from "./main-layout.json";
import services from "./services/index";

const en = {
  ...main,
  ...common,
  ...authentication,
  ...layout,
  ...services
};
export default en;
