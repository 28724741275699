import { createRouter, createWebHistory } from "vue-router";
import moment from "moment";
import "moment/dist/locale/ar";
import i18n from "../libs/i18n";
import NotFound from "../layouts/NotFound.vue";
import pages from "./routes/pages";
import { useUserStore } from "@/stores/user";
import { changeLocale } from "wameed-ui";
import { nextTick } from "vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: `/${i18n.global.locale.value}/`
    },
    {
      path: "/:lang",
      children: [
        ...pages,
        {
          path: ":pathMatch(.*)*",
          meta: {
            layout: "pattern"
          },
          name: "not-found",
          component: NotFound
        }
      ]
    }
  ]
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  const langs = ["ar", "en"];
  if (langs.includes(to.params.lang)) {
    i18n.global.locale.value = to.params.lang;
    const getHTMLTag = document.documentElement;
    getHTMLTag.setAttribute("lang", to.params.lang);
    getHTMLTag.setAttribute("dir", to.params.lang == "ar" ? "rtl" : "ltr");
    changeLocale(to.params.lang);
    moment.locale(to.params.lang);


    if (to.meta.middleware) {
      const middleware = Array.isArray(to.meta.middleware)
        ? to.meta.middleware
        : [to.meta.middleware];

      const context = {
        from,
        next,
        router,
        to
      };
      const nextMiddleware = nextFactory(context, middleware, 1);

      return middleware[0]({ ...context, next: nextMiddleware });
    }
    return next();
  } else {
    if (to.name)
      next({ name: to.name, params: { lang: i18n.global.locale.value } });
    else next({ name: "home", params: { lang: i18n.global.locale.value } });
  }
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to) => {
  checkPermissions(to);
});

export async function removeElementsFromDom(className) {
  await nextTick(() => {
    let elements = document.getElementsByClassName(className);
    Array.from(elements).forEach((el) => {
      if (className == "permissions-switch-item") {
        el.setAttribute("disabled", true);
      } else {
        el.parentNode.removeChild(el);
      }
    });
  }).then(() => {
  });
}

export function checkPermissions(to) {
  const userStore = useUserStore();
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    // appLoading.style.display = 'none';
  }
  if (to.meta.pagePermissions && userStore.getPermissions) {
    if (
      to.meta.pagePermissions.create &&
      !userStore.getPermissions.includes(to.meta.pagePermissions.create)
    ) {
      removeElementsFromDom("permission-create-item");
    }
    if (
      to.meta.pagePermissions.assignShields &&
      !userStore.getPermissions.includes(to.meta.pagePermissions.assignShields)
    ) {

      removeElementsFromDom("permissions-assign_shields-item");
    }
    if (
      to.meta.pagePermissions.toggle &&
      !userStore.getPermissions.includes(to.meta.pagePermissions.toggle)
    ) {
      removeElementsFromDom("permissions-toggle-item");
      removeElementsFromDom("permissions-switch-item");
      removeElementsFromDom("permissions-accept_location-item");
      removeElementsFromDom("permissions-reject_location-item");
    }

    if (
      to.meta.pagePermissions.read &&
      !userStore.getPermissions.includes(to.meta.pagePermissions.read)
    ) {
      removeElementsFromDom("permissions-details-item");
      removeElementsFromDom("permissions-show-item");
      removeElementsFromDom("permissions-info-item");
    }

    if (
      to.meta.pagePermissions.delete &&
      !userStore.getPermissions.includes(to.meta.pagePermissions.delete)
    ) {
      removeElementsFromDom("permissions-delete-item");
      removeElementsFromDom("permissions-delete_2-item");
    }
    if (
      to.meta.pagePermissions.send &&
      !userStore.getPermissions.includes(to.meta.pagePermissions.send)
    ) {
      removeElementsFromDom("permissions-send_notification-item");
      removeElementsFromDom("permissions-resend-item");
    }
    if (
      to.meta.pagePermissions.edit &&
      !userStore.getPermissions.includes(to.meta.pagePermissions.edit)
    ) {
      removeElementsFromDom("permissions-alter-item");
      removeElementsFromDom("permissions-update-item");
      removeElementsFromDom("permissions-view-item");
      removeElementsFromDom("permissions-stop-item");
      removeElementsFromDom("permissions-switch-item");
      removeElementsFromDom("permissions-toggle-item");
      removeElementsFromDom("permissions-edit-item");

    }
  }
  //  detail,stop,send_notification,view,alter,delete,resend
}

router.resolve({
  name: "not-found",
  params: { pathMatch: ["not", "found"], lang: i18n.global.locale.value }
}).href; // '/not/found'
export default router;
