{
  "shields": {
    "create_new": "add new shield",
    "delete": "Delete shield",
    "table": {
      "name": "armor",
      "status": "shield status",
      "actions": "actions"
    }
  }
}