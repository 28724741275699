import users from "./users.json";
import shields from "./shields.json";
import locations from "./locations.json";
import sessions from "./sessions.json";
import reviews from "./reviews.json";

const serviceAr = {
  ...users,
  ...shields,
  ...locations,
  ...sessions,
  ...reviews,
};
export default serviceAr;
