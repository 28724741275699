{
  "shields": {
    "create_new": "إضافة درع جديد",
    "update": "تعديل الدرع",
    "delete": "حذف الدرع",
    "table": {
      "name": "الدرع",
      "status": "حالة الدرع",
      "actions": "الاجراءات"
    }
  }
}