<script setup>
import { RouterLink, RouterView, useRouter } from "vue-router";

const router = useRouter();


</script>

<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="no-gutters auth-inner m-0">
      <b-col class="d-flex flex-column justify-content-between align-items-center auth-bg px-5" lg="5">
        <b-col class="auth-header mx-auto d-flex align-items-center justify-content-between" lg="9" md="9" sm="8">
          <div class="d-flex justify-content-center align-items-center">
            <full-logo />
          </div>

          <div class="ml-2 ms-auto">
            <RouterLink class="text-med-18 text-gray-2 forgot-password-link mb-3 lang-link" href="#" :to="{
              name: router.currentRoute.value.name,
              params: {
                lang: $i18n.locale == 'ar' ? 'en' : 'ar',
              },
            }">
              <span v-if="$i18n.locale == 'en'">
                <span class="lang-text">English</span>
                <en-icon />
              </span>
              <span v-else>
                <span class="lang-text">العربيه</span>
                <ar-icon />
              </span>
            </RouterLink>
          </div>
        </b-col>
        <b-col class="d-flex align-items-center flex-wrap flex-1 w-100">
          <b-col class="auth-body mx-auto flex-1" lg="9" md="9" sm="8">
            <router-view />
          </b-col>
        </b-col>
<!--        <div class="d-flex flex-column align-items-center flex-0 pt-5 ">-->
<!--          <div class="text-med-14 text-gray-2 mt-5">-->
<!--            {{ $t("footer.all_rights_reserved") }}-->
<!--            <a class="text-bold-14 text-primary-600">-->
<!--              {{ $t("footer.platform") }}-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
      </b-col>
      <b-col id="login-img" class="d-none d-lg-flex align-items-center p-5 login-bg" lg="7">
        <div class="content-cards">
          <div class="content-cards-logo">
            <white-logo />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
