{
  "settings": {
    "main_settings": "الإعدادات الأساسية",
    "main_settings_subtitle": "بيانات المنصة، مواقع التواصل الإجتماعي",
    "system_users": "مستخدمي النظام",
    "system_users_subtitle": "المستخدمين، الصلاحيات، سجل العمليات",
    "profile": "بيانات المستخدم",
    "profile_subtitle": "تعديل بياناتك الشخصية",
    "password": "كلمة المرور",
    "advance_setting": "اعدادات متقدمة",
    "language": "اللغة",
    "personal_information": "البيانات الشخصية",
    "notifications_setting": "صوت الإشعارات",
    "welcome_user": "أهلا بك",
    "update_personal_information": "تعديل البيانات الشخصية",
    "change_passowrd": "تعديل كلمة المرور",
    "reset_password": "إعادة تعيين كلمة المرور",
    "shield_settings": "الدروع",
    "shield_subtitle": "إضافة وعرض الدروع"
  },
  "platform": {
    "points": "آلية احتساب النقاط",
    "points_subtitle": "بيانات آلية احتساب النقاط",
    "app_explanation": "شرح التطبيق",
    "app_explanation_subtitle": "شرح واجهات التطبيق",
    "subscription": "الاشتراك في (Premium) ",
    "subscription_subtitle": "تعديل بياناتك الشخصية",
    "social_media": "تابعنا على منصات التواصل الاجتماعي",
    "social_media_subtitle": "أرقام وروابط التواصل الإجتماعي",
    "prizes": "الجوائز",
    "prizes_subtitle": "بيانات الجوائز",
    "terms_and_conditions": "الشروط والأحكام",
    "terms_and_conditions_subtitle": "محتوى الشروط والأحكام",
    "our_league": "عن دورينا",
    "our_league_subtitle": "معلومات عن دورينا",
    "about_platform": "عن المنصة",
    "about_platform_subtitle": "تعديل بياناتك الشخصية",
    "faq": "الأسئلة الشائعة",
    "faq_subtitle": "التحكم بالأسئلة الشائعة",
    "playing_rules": "شروط وقواعد المسابقة",
    "playing_rules_subtitle": "معلومات شروط وقواعد المسابقة",
    "privacy_policy": "سياسة الخصوصية",
    "privacy_policy_subtitle": "محتوى سياسة الخصوصية",
    "hide": "إخفاء الصفحة",
    "show": "إضهار الصفحة"
  },
  "points": {
    "title": "آلية احتساب النقاط",
    "tie_cases": "حالات التعادل",
    "table": {
      "name": "حالة التوقع",
      "normal_match_points": "المباراة العادية",
      "fire_match_points": "المباراة  النارية",
      "actions": "الاجراءات"
    }
  },
  "faq": {
    "title": "الأسئلة الشائعة",
    "create_new": "إضافة سؤال جديد",
    "details": "تفاصيل السؤال",
    "table": {
      "question": "السؤال",
      "answer": "الإجابة",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    }
  },
  "advertisements": {
    "create_new": "إضافة إعلان جديد",
    "table": {
      "profile": "الإعلان",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "alter": "تعديل الإعلان",
      "delete": "حذف الإعلان"
    }
  },
  "notifications": {
    "send_new_notification": "إضافة إشعار جديد",
    "send": "إرسال الإشعار",
    "table": {
      "name": "عنوان الإشعار",
      "content": "المحتوى",
      "created_at": "تاريخ الإضافة",
      "type_id": "الفئة المستهدفة",
      "actions": "الاجراءات"
    },
    "users": "المستخدمين",
    "targets": "الفئة المستهدفة",
    "add_target": "إضافة فئة إستهداف",
    "user": "مستخدمين",
    "all_users": "جميع المستخدمين",
    "specific_user": "مستخدمين محددين",
    "winners": "الفائزين",
    "by_age": "حسب العمر",
    "gender": "الجنس",
    "all": "الكل",
    "specific": "مخصص",
    "male_only": "ذكور فقط",
    "female_only": "نساء فقط",
    "specific_age": "العمر",
    "from_to": "من -- الى --",
    "select_all": "تحديد الكل",
    "date_error": "يجب ان يكون حقل البدايه اكبر من النهاية"
  },
  "sys-roles": {
    "create_new": "إضافة صلاحية جديدة",
    "update_role": "تعديل صلاحية",
    "delete": "حذف الصلاحية",
    "table": {
      "name": "مسمى الصلاحية",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "view": "عرض التفاصيل"
    }
  },
  "permissions": {
    "sections": {
      "dashboard": "الرئيسية",
      "sessions": "الجلسات",
      "locations": "المواقع",
      "users": "المستخدمين",
      "notifications": "الإشعارات",
      "admin_users": "مستخدمي النظام",
      "roles": "الصلاحيات",
      "user_profile": "إعدادات الحساب الشخصي",
      "shields": "الدروع"
    },
    "read_shields": "عرض الدروع",
    "edit_shields": "تحرير الدروع",
    "delete_shields": "حذف الدروع",
    "add_shields": "إضافة الدروع",
    "read_dashboard": "عرض الرئيسية",
    "read_users": "عرض المستخدمين",
    "read_user_details": "عرض تفاصيل المستخدم",
    "toggle_users": "تفعيل/تعطيل المستخدمين",
    "assign_shields": "تعيين الدروع",
    "edit_users": "تحرير المستخدمين",
    "delete_users": "حذف المستخدمين",
    "read_notifications": "عرض الإشعارات",
    "send_notifications": "إرسال الإشعارات",
    "delete_notifications": "حذف الإشعارات",
    "read_platform": "عرض معلومات المنصة",
    "edit_platform": "تحرير معلومات المنصة",
    "read_platform_pages": "عرض محتوى المنصة",
    "edit_platform_pages": "تحرير محتوى المنصة",
    "read_platform_faq": "عرض الأسئلة الشائعة",
    "add_platform_faq": "إضافة الأسئلة الشائعة",
    "edit_platform_faq": "تحرير الأسئلة الشائعة",
    "delete_platform_faq": "حذف الأسئلة الشائعة",
    "read_platform_points": "عرض طريقة احتساب النقاط",
    "edit_platform_points": "تحرير طريقة احتساب النقاط",
    "read_admin_users": "عرض مستخدمي النظام",
    "add_admin_users": "إضافة مستخدمي النظام",
    "edit_admin_users": "تحرير مستخدمي النظام",
    "delete_admin_users": "حذف مستخدمي النظام",
    "read_admin_users_logs": "عرض سجل مستخدمي النظام",
    "reset_password_admin_users": "إعادة تعيين كلمة مرور مستخدمي النظام",
    "read_roles": "عرض الصلاحيات",
    "add_roles": "إضافة الصلاحيات",
    "edit_roles": "تحرير الصلاحيات",
    "delete_roles": "حذف الصلاحيات",
    "read_sessions": "عرض الجلسات",
    "read_session_details": "عرض تفاصيل الجلسة",
    "add_locations": "إضافة المواقع",
    "edit_locations": "تحرير المواقع",
    "delete_locations": "حذف المواقع",
    "read_locations": "عرض المواقع",
    "read_location_details": "عرض تفاصيل الموقع",
    "toggle_locations": "تفعيل/تعطيل المواقع"
  },
  "sys-users": {
    "create_new": "إضافة مستخدم جديد",
    "view_logs": "عرض سجل العمليات",
    "roles_management": "إدارة الصلاحيات",
    "logs": "سجل العمليات",
    "resend_password": "إعادة إرسال كلمة المرور",
    "delete": "حذف المستخدم",
    "table": {
      "name": "اسم المستخدم",
      "role": "المسمى",
      "last_activity": "اخر نشاط",
      "status": "الحالة",
      "actions": "الاجراءات"
    }
  },
  "logs": {
    "table": {
      "user": "اسم المستخدم",
      "title": "اسم العملية",
      "content": "تفاصيل العملية",
      "created_date_time": "تاريخ العملية"
    }
  },
  "dashboard": {
    "statistics": {
      "title": "الإحصائيات",
      "total_users": "إجمالي المستخدمين",
      "total_locations": "إجمالي المواقع",
      "total_suggested_locations": "إجمالي المواقع المقترحة",
      "total_sessions": "إجمالي الجلسات"
    },
    "table": {
      "location": "الموقع",
      "user": "المستخدم",
      "suggested_date": "تاريخ الإقتراح",
      "rate": "التقييم",
      "session_count": "عدد الجلسات",
      "sessions": "جلسة"
    },
    "last_7_days": "آخر 7 ايام",
    "last_30_days": "آخر 30 يوم",
    "last_12_months": "آخر 12 شهر",
    "males": "ذكور",
    "females": "إناث",
    "request": "طلب",
    "resent_users": "احدث المستخدمين",
    "user": "مستخدم",
    "most_rated_locations": "أكثر المواقع تقييماُ",
    "suggested_locations": "مقترحات المواقع",
    "players_ranking": "إحصائية رتب اللاعبين"
  },
  "teams": {
    "create_new": "إضافة فريق جديد",
    "update": "تعديل البيانات",
    "details": "تفاصيل الفريق",
    "table": {
      "profile": "الفريق",
      "championships": "البطولات",
      "created_at": "تاريخ الإضافة",
      "actions": "الاجراءات"
    }
  },
  "championships": {
    "create_new": "إضافة بطولة جديدة",
    "update": "تعديل البيانات",
    "details": "تفاصيل البطولة",
    "total_matches": "عدد المباريات",
    "table": {
      "profile": "البطولة",
      "total_teams": "عدد الفرق",
      "start_date": "تاريخ البدء",
      "end_date": "تاريخ الإنتهاء",
      "created_at": "تاريخ الإضافة",
      "actions": "الاجراءات"
    }
  },
  "matches": {
    "create_new": "إضافة مباراة جديدة",
    "update": "تعديل البيانات",
    "details": "تفاصيل المباراة",
    "fire": "نارية",
    "normal": "عادية",
    "table": {
      "team_a": "الفريق الأول",
      "team_b": "الفريق الثاني",
      "score": "النتيجة",
      "match_date": "وقت وتاريخ المباراة",
      "match_type": "نوع المباراة",
      "match_result": "الحالة",
      "actions": "الاجراءات"
    },
    "disabled": "معطلة",
    "waiting_for_results": "في انتظار النتيجة",
    "finished": "مكتملة",
    "on_going": "قائمة",
    "total_predictions": "إجمالي التوقعات",
    "championship": "البطولة",
    "date_time": "الوقت والتاريخ"
  },
  "championship_teams": {
    "table": {
      "profile": "الفريق",
      "total_matches": "عدد المباريات",
      "created_at": "تاريخ الإضافة",
      "actions": "الاجراءات"
    }
  },
  "predictions": {
    "table": {
      "profile": "اسم المستخدم",
      "score": "التوقع",
      "created_at": "تاريخ",
      "prediction_result": "نتيجة التوقع",
      "actions": "الاجراءات"
    },
    "prediction_results": {
      "0": "-",
      "1": " أقل من 10%",
      "2": "توقع صحيح",
      "3": "توقع الفوز الصحيح ",
      "4": "توقع عدد الأهداف",
      "5": "توقع الفارق الصحيح",
      "6": "توقع خاطئ",
      "7": "توقع عدد الأهداف و الفوز الصحيح"
    },
    "statistics": {
      "correct": "التوقعات الصائبة",
      "wrong": "التوقعات الخاطئة",
      "winner": "توقعات الفوز والتعادل الصحيحة ",
      "difference": "توقعات الفارق الصحيح",
      "close": "توقعات عدد الأهداف",
      "less_10_percentage": "توقعات الهاترك"
    }
  },
  "winners-periods": {
    "create_new": "إضافة فائزين",
    "details": "تفاصيل فترة الفوز ",
    "table": {
      "period_type": "نوع الفائزين",
      "name": "اسم الفترة",
      "period": "الفترة",
      "total_winners": "عدد الفائزين",
      "actions": "الاجراءات"
    },
    "period_type": {
      "1": "أسبوعي",
      "2": "شهري",
      "3": "موسمي"
    },
    "period_types": {
      "1": "أسبوعيين",
      "2": "شهريين",
      "3": "موسميين"
    },
    "choose_winners": "اختيار الفائزين ",
    "weekly_points": "النقاط الأسبوعية: ",
    "monthly_points": "النقاط الشهرية: ",
    "yearly_points": "النقاط السنوية: ",
    "start_date": "تاريخ البدء",
    "end_date": "تاريخ الإنتهاء",
    "total_points": "النقاط"
  },
  "user-predictions": {
    "table": {
      "match": "المباراة",
      "score": "التوقع",
      "created_at": "تاريخ",
      "prediction_result": "نتيجة التوقع",
      "actions": "الاجراءات"
    },
    "prediction_results": {
      "0": "-",
      "1": " أقل من 10%",
      "2": "توقع صحيح",
      "3": "توقع الفوز الصحيح ",
      "4": "توقع عدد الأهداف",
      "5": "توقع الفارق الصحيح",
      "6": "توقع خاطئ",
      "7": "توقع عدد الأهداف و الفوز الصحيح"
    },
    "statistics": {
      "correct": "التوقعات الصائبة",
      "wrong": "التوقعات الخاطئة",
      "winner": "توقعات الفوز والتعادل الصحيحة ",
      "difference": "توقعات الفارق الصحيح",
      "close": "توقعات عدد الأهداف",
      "less_10_percentage": "توقعات الهاترك"
    }
  },
  "winners": {
    "details": "تفاصيل الفائز",
    "table": {
      "profile": "الفائز",
      "order_num": "الترتيب",
      "points": "النقاط",
      "actions": "الاجراءات"
    }
  },
  "groups": {
    "details": "تفاصيل الدوري",
    "table": {
      "profile": "الدوري",
      "total_members": "عدد الأعضاء",
      "username": "مشرف الدوري",
      "championships": "البطولات",
      "created_at": "تاريخ الإنشاء",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "total_championships": "عدد البطولات"
  },
  "members": {
    "title": "الأعضاء",
    "details": "تفاصيل المستخدم",
    "table": {
      "profile": "المستخدم",
      "total_points": "النقاط",
      "total_predictions": "عدد التوقعات",
      "created_at": "تاريخ الإنضمام",
      "actions": "الاجراءات"
    }
  },
  "group-championships": {
    "table": {
      "profile": "البطولة",
      "total_teams": "عدد الفرق",
      "created_at": "تاريخ الإضافة",
      "actions": "الاجراءات"
    }
  },
  "google_ads": {
    "type": {
      "1": "إعلانات عدد التوقعات",
      "2": "إعلانات بين العناصر",
      "3": "إعلانات أسفل الصفحة",
      "4": "إعلانات التنقل بين الصفحات",
      "5": "إعلانات  البنر الإعلاني"
    }
  },
  "subscription-products": {
    "table": {
      "product_id": "معرف الإشتراك",
      "product_type": "نوع الإشتراك",
      "product_period": "المدة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "product_type": {
      "1": "شهري",
      "2": "سنوي"
    },
    "product_period": {
      "1": "شهر",
      "2": "سنة"
    },
    "total_championships": "عدد البطولات"
  },
  "subscribers": {
    "details": "تفاصيل المشترك",
    "table": {
      "profile": "المستخدم",
      "product_type": "نوع الإشتراك",
      "provider": "المتجر",
      "receipt_status": "الحالة في المتجر",
      "start_date": "تاريخ الإشتراك",
      "expire_date": "تاريخ إنتهاء الإشتراك",
      "status": "حالة الإشتراك",
      "actions": "الاجراءات"
    },
    "receipt_status": {
      "1": "مشترك",
      "2": "منتهي",
      "3": "ملغي"
    },
    "provider": {
      "google": "جوجل",
      "apple": "ابل"
    },
    "product_type": {
      "1": "شهري",
      "2": "سنوي"
    },
    "product_period": {
      "1": "شهر",
      "2": "سنة"
    },
    "subscription_details": "معلومات الاشتراك"
  }
}