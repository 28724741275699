import { h, resolveComponent } from "vue";
import { permissions } from "@/utils/permissions-enum";
import middleware from "../middleware/middleware";

export default [
  {
    path: "locations",

    component: {
      render() {
        return h(resolveComponent("router-view"));
      }
    },
    children: [
      {
        path: "",
        name: "locations",
        component: () => import("@/views/locations/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.locations",
          breadcrumbs: [
            {
              path: "locations",
              title: "sidebar.locations",
              disabled: true
            }
          ],
          permissions: [permissions.readLocations],
          pagePermissions: {
            create: permissions.addLocations,
            edit: permissions.editLocations,
            read: permissions.readLocationDetails,
            delete: permissions.deleteLocations
          }
        }
      },
      {
        path: ":id",
        name: "locations-details",
        component: () => import("@/views/locations/details.vue"),
        meta: {
          middleware: middleware,
          title: "locations.details",
          breadcrumbs: [
            {
              path: "locations",
              title: "sidebar.locations"
            },
            {
              path: "locations-details",
              title: "locations.details",
              disabled: true
            }
          ],
          permissions: [permissions.readLocationDetails],
          pagePermissions: {
            toggle: permissions.toggleLocations,
            edit: permissions.editLocations,
            read: permissions.readLocationDetails,
            delete: permissions.deleteLocations
          }
        }
      },
      {
        path: "suggested",
        name: "suggested-locations",
        component: () => import("@/views/locations/suggested.vue"),
        meta: {
          middleware: middleware,
          title: "locations.suggested",
          breadcrumbs: [
            {
              path: "locations",
              title: "sidebar.locations"
            },
            {
              path: "suggested-locations",
              title: "locations.suggested",
              disabled: true
            }//todo;: fix this locations perimssions
          ],
          permissions: [permissions.readLocations],
          pagePermissions: {
            read: permissions.readLocationDetails,
            toggle: permissions.toggleLocations,
            create: permissions.addLocations,
            edit: permissions.editLocations,
            delete: permissions.deleteLocations
          }
        }
      },
      {
        path: "suggested/:id",
        name: "suggested-locations-details",
        component: () => import("@/views/locations/suggested_details.vue"),
        meta: {
          middleware: middleware,
          title: "locations.details",
          breadcrumbs: [
            {
              path: "locations",
              title: "sidebar.locations"
            },
            {
              path: "suggested-locations",
              title: "locations.suggested",
              disabled: true
            },
            {
              path: "locations-details",
              title: "locations.details",
              disabled: true
            }
          ],
          permissions: [permissions.readLocationDetails],
          pagePermissions: {
            toggle: permissions.toggleLocations,
            create: permissions.addLocations,
            edit: permissions.editLocations,
            read: permissions.readLocationDetails,
            delete: permissions.deleteLocations
          }
        }
      }
    ]
  }
];
