import { defineStore } from "pinia";
export const useAppsStore = defineStore({
  id: "apps",
  state: () => ({
    errors: {},
  }),
  getters: {
    getErrors: (state) => state.errors,
  },
  actions: {
    async setErrors(errors) {
      this.errors = errors;
    },
  },
});
