const permissions = {


  readDashboard: "read dashboard",


  readUsersInfo: "read users",
  readUserDetails: "read user details",
  toggleUsers: "toggle users",
  assignShields: "assign shields",

  // editUsers: "edit users",
  // deleteUsers: "delete users",


  readNotifications: "read notifications",
  sendNotifications: "send notifications",
  deleteNotifications: "delete notifications",

  readPlatform: "read platform",
  editPlatform: "edit platform",

  readPlatformPages: "read platform pages",
  editPlatformPages: "edit platform pages",


  readPlatformFaq: "read platform faq",
  editPlatformFaq: "edit platform faq",
  createPlatformFaq: "add platform faq",
  deletePlatformFaq: "delete platform faq",


  readAdminUsers: "read admin users",
  addAdminUsers: "add admin users",
  editAdminUsers: "edit admin users",
  readLogs: "read admin users logs",
  resetPasswordAdminUsers: "reset password admin users",
  deleteAdminUsers: "delete admin users",

  readRoles: "read roles",
  addRoles: "add roles",
  editRoles: "edit roles",
  deleteRoles: "delete roles",


  readShields: "read shields",
  addShields: "add shields",
  editShields: "edit shields",
  deleteShields: "delete shields",


  readLocations: "read locations",
  readLocationDetails: "read location details",
  toggleLocations: "toggle locations",
  addLocations: "add locations",
  editLocations: "edit locations",
  deleteLocations: "delete locations",

  readSessions: "read sessions",
  readSessionDetails: "read session details"

};

export { permissions };
