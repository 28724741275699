import { useCookies } from "vue3-cookies";
import { useUserStore } from "@/stores/user";
export default async function middleware({ to, next }) {
  const { cookies } = useCookies();
  let loginRoutes = [
    "login",
    "forgot-password",
    "reset-password",
    "validate-email",
  ];
  let loginData = cookies.get("login-data");
  if (loginData) {
    const userStore = useUserStore();
    if (loginRoutes.includes(to.name)) {
      return next({ name: "home", params: { lang: to.params.lang } });
    }
    if (to.meta.permissions) {
      if (!userStore.getPermissions) {
        await userStore._getProfile()
      }
      if (
        
        !userStore.getPermissions.some((value) =>
          to.meta.permissions.includes(value)
        )
      ) {
        return next({ name: "home", params: { lang: to.params.lang } });
      }
    }
     return next();
  } else {
    if (!loginRoutes.includes(to.name))
      return next({ name: "login", params: { lang: to.params.lang } });
    return next();
  }
}
