{
  "header": {
    "notifications": "الإشعارات",
    "show_all_notifications": "عرض كل الإشعارات",
    "change_language": "تغيير اللغة"
  },
  "sidebar": {
    "locations": "المواقع",
    "sessions": "الجلسات",
    "home": "الرئيسية",
    "users": "المستخدمين",
    "notifications": "الإشعارات",
    "settings": "الاعدادات",
    "sys-roles": "الصلاحيات",
    "sys-users": "مستخدمي النظام",
    "user_profile": "إعدادات الحساب الشخصي"
  },
  "footer": {
    "all_rights_reserved": "جميع الحقوق محفوظة لمنصة",
    "platform": "Rabbe",
    "made_by": "صنع في",
    "wameed": "وميض"
  }
}