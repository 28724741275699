// start  general icons and pages icons
import notfound from "./general/404.svg?component";
// predictions icons
import circleCheckIcon from "./general/circle-check.svg?component";
import circleCloseIcon from "./general/circle-close.svg?component";
import circleInfoIcon from "./general/circle-info.svg?component";
//
import fullLogo from "./general/full-logo.svg?component";
import logsIcon from "./general/logs.svg?component"; // system users icons
import noIntenet from "./general/no-intenet.svg?component";
import rankIcon from "./general/rank.svg?component"; // user icons
import rolesIcon from "./general/roles.svg?component"; // system users icons
import userPointsIcon from "./general/user-points.svg?component"; // user icons
import dividerIcon from "./general/divider.svg?component"; // user icons
// end  general icons and pages icons
// start header icons
import arIcon from "./header/ar.svg?component";
import enIcon from "./header/en.svg?component";
import languageIcon from "./header/language.svg?component";
import logoutIcon from "./header/logout.svg?component";
import notificationIcon from "./header/notification.svg?component";
import notification2Icon from "./header/notification2.svg?component";
// end header icons
// start  small icons
import redoIcon from "./icons/redo-icon.svg?component";
import arrowIcon from "./icons/arrow-icon.svg?component";
import arrowIcon2 from "./icons/arrow-icon2.svg?component";
import editIcon from "./icons/edit-icon.svg?component";
import edit2Icon from "./icons/edit2-icon.svg?component";
import close2Icon from "./icons/close2-icon.svg?component";
import acceptIcon from "./icons/accept-icon.svg?component";
import acceptLocationIcon from "./icons/accept-location-icon.svg?component";
import rejectLocationIcon from "./icons/reject-location-icon.svg?component";
import rejectIcon from "./icons/reject-icon.svg?component";
import rejectIconRed from "./icons/reject-icon-red.svg?component";
import emailIcon from "./icons/email.svg?component";
import eyeoffIcon from "./icons/eye-off.svg?component";
import eyeonIcon from "./icons/eye-on.svg?component";
import lockIcon from "./icons/lock.svg?component";
import plusIcon from "./icons/plus.svg?component";
import reloadIcon from "./icons/reload-icon.svg?component";
import tieIcon from "./icons/tie.svg?component";
import timerIcon from "./icons/timer.svg?component";
// start  small icons
// start platform icons
import appExplanationIcon from "./platform-content/app-explanation.svg?component";
import faqIcon from "./platform-content/faq.svg?component";
import ourLeagueIcon from "./platform-content/our-league.svg?component";
import platformIcon from "./platform-content/platform.svg?component";
import pointsIcon from "./platform-content/points.svg?component";
import prizesIcon from "./platform-content/prizes.svg?component";
import privacyPolicyIcon from "./platform-content/privacy-policy.svg?component";
import socialMediaIcon from "./platform-content/social-media.svg?component";
import subscriptionIcon from "./platform-content/subscription.svg?component";
import termsAndConditionsIcon from "./platform-content/terms-and-conditions.svg?component";
// end platform icons
// start setting icons
import profileIcon from "./settings/profile.svg?component";
import systemUsersIcon from "./settings/system-users.svg?component";
import shieldIcon from "./settings/shield.svg?component";
import assignShieldIcon from "./settings/assign-shield.svg?component";
// end setting icons
// start sidebar icons
import advertisementsIcon from "./sidebar/advertisements.svg?component";
import appContentIcon from "./sidebar/app-content.svg?component";
import championshipsIcon from "./sidebar/championships.svg?component";
import collapseIcon from "./sidebar/collapse.svg?component";
import groupsIcon from "./sidebar/groups.svg?component";
import sidebarPattern from "./patterns/sidebar.svg?component";

//start important common component wameed ui (not the botton icons ) + project
import homeIcon from "./sidebar/home.svg?component";
//end important common component wameed ui (not the botton icons ) + project
import matchesIcon from "./sidebar/matches.svg?component";
import menuIcon from "./sidebar/menu.svg?component";
import notificationsIcon from "./sidebar/notifications.svg?component";
import settingsIcon from "./sidebar/settings.svg?component";
import settings2Icon from "./sidebar/settings2.svg?component";
import subscribersIcon from "./sidebar/subscribers.svg?component";
import teamsIcon from "./sidebar/teams.svg?component";
import usersIcon from "./sidebar/users.svg?component";
import usersIcon2 from "./sidebar/users2.svg?component";
import sessionsIcon from "./sidebar/sessions.svg?component";
import starFillIcon from "./sidebar/star-fill.svg?component";
import locationsIcon from "./sidebar/locations.svg?component";
import suggestedLocationsIcon from "./sidebar/suggested_locations.svg?component";
import suggestedLocationsIcon2 from "./sidebar/suggested_locations2.svg?component";
import whiteFullLogo from "./sidebar/white-full-logo.svg?component";
import whiteLogo from "./sidebar/white-logo.svg?component";
import winnersIcon from "./sidebar/winners.svg?component";
// end sidebar icons
// start table icons
import activateIcon from "./table/activate.svg?component";
import blockIcon from "./table/block.svg?component";
import deleteIcon from "./table/delete.svg?component";
import detailsIcon from "./table/details.svg?component";
import resendIcon from "./table/resend.svg?component";
import sendNotificationIcon from "./table/send-notification.svg?component";
import stopIcon from "./table/stop.svg?component";
import updateIcon from "./table/update.svg?component";
// end table icons
// start wameed ui  icons
import arrowDownIcon from "./wameed-ui/arrow-down.svg?component"; // common
import calenderIcon from "./wameed-ui/calender-icon.svg?component"; // not called
import closeIcon from "./wameed-ui/close-icon.svg?component"; // common
import filterIcon from "./wameed-ui/filter.svg?component"; // not called
import imageDeleteIcon from "./wameed-ui/image-delete.svg?component"; // not called
import imageeditIcon from "./wameed-ui/image-edit.svg?component"; // not called
import imageUploadIcon from "./wameed-ui/image-upload.svg?component"; //  called
import noDataIcon from "./wameed-ui/no-data.svg?component"; // not called
import searchIcon from "./wameed-ui/search-icon.svg?component"; // common
import SortableIcon from "./wameed-ui/sortable-icon.svg?component"; // common
import verticalDotsIcon from "./wameed-ui/vertical-dots.svg?component"; // common
import warningIcon from "./wameed-ui/warning-icon.svg?component"; //  called
// end wameed ui  icons


const svgComponents = (app) => {
  return (
    app
      // start  wameed ui components 
      // important  wameed ui not called  components
      .component("calender-icon", calenderIcon)
      .component("filter-icon", filterIcon)
      .component("image-edit-icon", imageeditIcon)
      .component("image-delete-icon", imageDeleteIcon)
      .component("nodata-icon", noDataIcon)

      // important  wameed ui  called  components
      .component("img-upload-icon", imageUploadIcon)
      .component("warning-icon", warningIcon)

      //important common component wameed ui (not the botton icons ) + project

      .component("arrow-down-icon", arrowDownIcon)
      .component("search-icon", searchIcon)
      .component("vertical-dots-icon", verticalDotsIcon)
      .component("home-icon", homeIcon)
      .component("close-icon", closeIcon)
      .component("sortable-icon", SortableIcon)
      .component("divider-icon", dividerIcon)

      // end  wameed ui components 


      // start  general icons
      .component("not-found", notfound)
      .component("full-logo", fullLogo)
      .component("no-internet", noIntenet)

      // system users icons
      .component("logs-icon", logsIcon)
      .component("roles-icon", rolesIcon)

      // predictions icons

      .component("circle-check-icon", circleCheckIcon)
      .component("circle-close-icon", circleCloseIcon)
      .component("circle-info-icon", circleInfoIcon)

      // user icons
      .component("user-points-icon", userPointsIcon)
      .component("rank-icon", rankIcon)
      // end  general icons


      // start  small icons
      .component("timer-icon", timerIcon)
      // input icons
      .component("eyeoff-icon", eyeoffIcon)
      .component("eyeon-icon", eyeonIcon)
      .component("email-icon", emailIcon)
      .component("lock-icon", lockIcon)

      .component("arrow-down-icon2", arrowIcon2)

      // button icons
      .component("plus-icon", plusIcon)
      .component("edit-icon", editIcon)
      .component("edit2-icon", edit2Icon)
      .component("close2-icon", close2Icon)
      .component("accept-icon", acceptIcon)
      .component("accept_location-icon", acceptLocationIcon)
      .component("reject_location-icon", rejectLocationIcon)
      .component("reject-icon", rejectIcon)
      .component("reject-icon-red", rejectIconRed)
      .component("arrow-icon", arrowIcon)
      .component("redo-icon", redoIcon)
      .component("reload-icon", reloadIcon)
      .component("tie-icon", tieIcon)

      // end  small icons

      // start header icons
      .component("ar-icon", arIcon)
      .component("en-icon", enIcon)
      .component("language-icon", languageIcon)
      .component("logout-icon", logoutIcon)
      .component("notification-icon", notificationIcon)
      .component("notification2-icon", notification2Icon)


      // sidebar icons
      .component("collapse-icon", collapseIcon)
      .component("white-logo", whiteLogo)
      .component("white-full-logo", whiteFullLogo)
      .component("sidebar-pattern", sidebarPattern)
      .component("dashboard-icon", homeIcon)
      .component("menu-icon", menuIcon)
      .component("matches-icon", matchesIcon)
      .component("teams-icon", teamsIcon)
      .component("championships-icon", championshipsIcon)
      .component("users-icon", usersIcon)
      .component("users-icon-2", usersIcon2)
      .component("sessions-icon", sessionsIcon)
      .component("star-fill-icon", starFillIcon)
      .component("locations-icon", locationsIcon)
      .component("suggested-locations-icon", suggestedLocationsIcon)
      .component("suggested-locations-icon2", suggestedLocationsIcon2)
      .component("subscribers-icon", subscribersIcon)
      .component("winners-icon", winnersIcon)
      .component("groups-icon", groupsIcon)
      .component("advertisements-icon", advertisementsIcon)
      .component("notifications-icon", notificationsIcon)
      .component("app-content-icon", appContentIcon)
      .component("settings-icon", settingsIcon)
      .component("settings2-icon", settings2Icon)


      // platform icons
      .component("points-icon", pointsIcon)
      .component("app-explanation-icon", appExplanationIcon)
      .component("subscription-icon", subscriptionIcon)
      .component("social-media-icon", socialMediaIcon)
      .component("prizes-icon", prizesIcon)
      .component("terms-and-conditions-icon", termsAndConditionsIcon)
      .component("our-league-icon", ourLeagueIcon)
      .component("platform-icon", platformIcon)
      .component("faq-icon", faqIcon)
      .component("privacy-policy-icon", privacyPolicyIcon)

      // setting icons
      .component("profile-icon", profileIcon)
      .component("sys-users-icon", systemUsersIcon)
      .component("shield-icon", shieldIcon)
      .component("assign-shield-icon", assignShieldIcon)
      .component("assign_shields-icon", assignShieldIcon)

      //  table  and some  action icons 
      .component("delete-icon", deleteIcon)
      .component("delete_2-icon", deleteIcon)
      .component("details-icon", detailsIcon)
      .component("info-icon", detailsIcon)
      .component("resend-icon", resendIcon)
      .component("send_notification-icon", sendNotificationIcon)
      .component("update-icon", updateIcon)
      .component("activate-icon", activateIcon)
      .component("match_result-icon", activateIcon)
      .component("alter-icon", updateIcon)
      .component("view-icon", detailsIcon)
      .component("block-icon", blockIcon)
      .component("disable-icon", blockIcon)
      .component("stop-icon", stopIcon)
      .component("stop_prediction-icon", stopIcon)
  );
};
export default svgComponents;
