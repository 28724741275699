{
  "reviews": {
    "table": {
      "created_user": "اسم المستخدم",
      "review": "محتوى التقييم",
      "rate": "التقييم",
      "create_time": "تاريخ التقييم",
      "actions": "الاجراءات"
    }
  }
}