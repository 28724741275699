import { createI18n } from "vue-i18n";
import ar from "./locales/ar";
import en from "./locales/en";
// import messages from "@intlify/unplugin-vue-i18n/messages";
export default  createI18n({
  legacy: false,
  globalInjection: true,
  locale: import.meta.env.VITE_APP_I18N_LOCALE || "ar",
  fallbackLocale: "ar",
  availableLocales: ["en", "ar"],
  messages: {
    ar: ar,
    en: en,
  },
});
