<script setup>
import { RouterView } from "vue-router";
import { useUserStore } from "../../stores/user";
const userStore = useUserStore();

defineProps({
  currentBreakpoint: {
    type: String,
  },
});
</script>

<template>
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div  v-if="userStore.getPermissions"  class="content-body">
        <RouterView />
      </div>
    </div>
  </div>
</template>
