{
  "locations": {
    "create_new": "add new location",
    "update": "edit data",
    "delete": "delete",
    "details": "location details",
    "search_location": "search location",
    "table": {
      "profile": "location",
      "created_at": "date added",
      "rate": "location rating",
      "status": "view location",
      "actions": "actions"
    },
    "form": {
      "name": "location name",
      "address": "description",
      "image": "location image",
      "geo_location": "Location address on the map"
    },
    "inactivate": "deactivate",
    "activate": "activate",
    "show_location": "show_location",
    "address": "location",
    "sessions": "sessions",
    "reviews": "location reviews"
  }
}