import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import i18n from "./libs/i18n";
import VueCookies from "vue3-cookies";
import App from "./App.vue";
import router from "./router";
import axiosInstance from "./libs/axios";
import svgComponents from "./assets/svg";
import BootstrapVue3 from "bootstrap-vue-3";
import "./assets/scss/index.scss";
import wameedLoading from "./utils/wameedLoading";
import wameedNotifications from "./utils/wameedNotifications";

import globalMixin from "./utils/globalMixin";
import sortable from "./utils/sortable-directive";
import SidebarLayout from "@/layouts/SidebarLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import FullLayout from "@/layouts/FullLayout.vue";
import PatternLayout from "@/layouts/PatternLayout.vue";
import permission from "./utils/permissions";

import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
const app = createApp(App);
import VueGoogleMaps from '@fawmi/vue-google-maps';

const pinia = createPinia();
wameedLoading(app);
wameedNotifications(app);
axiosInstance(app);
window.closeModal = function() {
  let body = document.querySelector("body");
  body.classList.remove("modal-open");
  body.style = "";
};

app.config.globalProperties.$cookies = app.use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});
pinia.use(({ store }) => {
  store.$http = markRaw(app.config.globalProperties.$http);
  store.$wameedLoading = markRaw(app.config.globalProperties.$wameedLoading);
  store.$wameedNotification = markRaw(
    app.config.globalProperties.$wameedNotification
  );
  store.$cookies = markRaw(app.config.globalProperties.$cookies);
});
app.use(pinia).use(router).use(i18n).use(BootstrapVue3).use(PerfectScrollbar);
app.use(VueGoogleMaps, {
  load: {
    key: import.meta.env.VITE_GOOGLE_MAP_KEY,
  },
})
app.mixin(globalMixin);
app.directive("permission", permission);
app.directive("sortable", sortable);

svgComponents(app);
app
  .component("full-layout", FullLayout)
  .component("pattern-layout", PatternLayout)
  .component("auth-layout", AuthLayout)
  .component("sidebar-layout", SidebarLayout);
app.provide("http", app.config.globalProperties.$http);

app.mount("#app");
