<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import NoInternet from "@/layouts/NoInternet.vue";
import { changeLocale, VeeValidateConfig } from "wameed-ui";

const router = useRouter();
let currentRouteMeta = ref(router.currentRoute);
const isLoading = ref(true);
const isOnline = ref(true);
onMounted(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 1500);
  checkIsOnline();
  changeLocale(router.currentRoute.value.params.lang);
});
VeeValidateConfig({
  en: {
    messages: {
      required: "Please fill this field"
    },
    names: {}
  },
  ar: {
    messages: {
      required: "يرجى تعبئة البيانات"
    },
    names: {}
  }
});
const checkIsOnline = () => {
  window.addEventListener("online", () => {
    isOnline.value = true;
  });
  window.addEventListener("offline", () => {
    isOnline.value = false;
  });
};
</script>
<template>
  <component
    v-if="!isLoading && isOnline"
    v-bind:is="`${
      currentRouteMeta.meta && currentRouteMeta.meta.layout
        ? currentRouteMeta.meta.layout
        : 'sidebar'
    }-layout`"
  />

  <div id="pattern-layout" v-if="!isLoading && !isOnline">
    <div class="top-pattern"></div>
    <no-internet @input="checkIsOnline" />
    <div class="bottom-pattern"></div>
  </div>
  <div v-if="isLoading" id="project-loading">
    <div class="spinner">
      <white-full-logo />

    </div>
  </div>
  <div id="loading-overly">
    <div class="spinner"></div>
  </div>

</template>