{
  "locations": {
    "create_new": "إضافة موقع جديد",
    "update": "تعديل البيانات",
    "delete": "حذف الموقع",
    "details": "تفاصيل الموقع",
    "suggested": "المواقع المقترحة",
    "phone": "رقم الهاتف",
    "search_location": "البحث عن موقع...",
    "table": {
      "profile": "الموقع",
      "created_at": "تاريخ الاضافة",
      "rate": "تقييم الموقع",
      "status": "عرض الموقع",
      "actions": "الاجراءات"
    },
    "form": {
      "name": "اسم الموقع",
      "address": "وصف عنوان الموقع",
      "image": "صورة الموقع",
      "geo_location": "الموقع في الخريطة"
    },
    "inactivate": "إلغاء التفعيل",
    "activate": "تفعيل",
    "show_location": "عرض الموقع",
    "address": "الموقع",
    "sessions": "جلسات الموقع",
    "reviews": "تقييمات الموقع"
  },
  "suggested_locations": {
    "title": "المواقع المقترحة",
    "accept": "قبول",
    "reject": "رفض",
    "table": {
      "profile": "الموقع",
      "created_user": "اسم المستخدم",
      "created_at": "تاريخ الاقتراح",
      "suggested_status": "حالة الموقع",
      "actions": "الاجراءات"
    },
    "status": {
      "pending": "قيد الانتظار",
      "accepted": "مقبول",
      "rejected": "مرفوض"
    }
  }
}