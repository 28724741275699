import { h, resolveComponent } from "vue";
import { permissions } from "@/utils/permissions-enum";
import middleware from "../middleware/middleware";
import sessionsRoutes from "./sessions";
import locationsRoutes from "./locations";

export default [
  {
    path: "login",
    name: "login",
    component: () => import("@/views/authentication/Login.vue"),
    meta: {
      middleware: middleware,
      layout: "auth",
      resource: "Auth"
    }
  },
  {
    path: "forgot-password",
    name: "forgot-password",
    component: () => import("@/views/authentication/ForgotPassword.vue"),
    meta: {
      middleware: middleware,
      layout: "auth",
      resource: "Auth"
    }
  },
  {
    path: "reset-password/:token",
    name: "reset-password",
    component: () => import("@/views/authentication/ResetPassword.vue"),
    meta: {
      middleware: middleware,
      layout: "auth",
      resource: "Auth"
    }
  },
  {
    path: "validate-email",
    name: "validate-email",
    component: () => import("@/views/authentication/ValidateEmail.vue"),
    meta: {
      middleware: middleware,
      layout: "auth",
      resource: "Auth"
    }
  },
  {
    path: "",
    name: "home",
    component: () => import("@/views/dashboard/index.vue"),
    meta: {
      middleware: middleware,
      title: "sidebar.home",
      breadcrumbs: []
    }
  },
  {
    path: "user-notifications",
    name: "user-notifications",
    component: () => import("@/views/user/Notifications.vue"),
    meta: {
      middleware: middleware,
      title: "sidebar.home",
      breadcrumbs: [
        {
          disabled: true,
          path: "notifications",
          title: "sidebar.notifications"
        }
      ]
    }
  },

  {
    path: "users",

    component: {
      render() {
        return h(resolveComponent("router-view"));
      }
    },
    children: [
      {
        path: "",
        name: "users",
        component: () => import("@/views/users/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.users",
          breadcrumbs: [
            {
              path: "users",
              title: "sidebar.users",
              disabled: true
            }
          ],
          permissions: [permissions.readUsersInfo],
          pagePermissions: {
            toggle: permissions.toggleUsers,
            read: permissions.readUserDetails,
            assignShields: permissions.assignShields,
          }
        }
      },
      {
        path: ":id",
        name: "users-details",
        component: () => import("@/views/users/details.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.users",
          breadcrumbs: [
            {
              path: "users",
              title: "sidebar.users"
            },
            {
              path: "users-details",
              title: "users.details",
              disabled: true
            }
          ],
          permissions: [permissions.readUserDetails],
          pagePermissions: {
            toggle: permissions.toggleUsers,
            assignShields: permissions.assignShields,
            read: permissions.readUsersInfo
          }
        }
      },
      {
        path: ":id/update",
        name: "users-update",
        component: () => import("@/views/users/update.vue"),
        meta: {
          middleware: middleware,
          title: "users.update",
          breadcrumbs: [
            {
              path: "users",
              title: "sidebar.users"
            },
            {
              path: "users-update",
              title: "users.update",
              disabled: true
            }
          ],
          permissions: [permissions.editUsers],
          pagePermissions: {
            edit: permissions.editTeams,
            read: permissions.readUsersInfo
          }
        }
      }
    ]
  },

  {
    path: "notifications",
    name: "notifications",
    component: () => import("@/views/notifications/index.vue"),
    meta: {
      title: "sidebar.notifications",
      middleware: middleware,
      breadcrumbs: [
        {
          disabled: true,
          path: "notifications",
          title: "sidebar.notifications"
        }
      ],
      permissions: [permissions.readNotifications],
      pagePermissions: {
        read: permissions.readNotifications,
        send: permissions.sendNotifications,
        delete: permissions.deleteNotifications
      }
    }
  },
  {
    path: "platform",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      }
    },
    children: [
      {
        path: "",
        name: "platform",
        component: () => import("@/views/platform/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.settings",
          breadcrumbs: [
            {
              disabled: true,
              path: "settings",
              title: "sidebar.settings"
            }
          ]
        }
      },
      {
        path: "points",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          }
        },
        children: [
          {
            path: "",
            name: "points",

            component: () => import("@/views/platform/points/index.vue"),
            meta: {
              middleware: middleware,
              title: "platform.points",
              breadcrumbs: [
                {
                  path: "platform",
                  title: "sidebar.app_content"
                },
                {
                  disabled: true,
                  path: "points",
                  title: "platform.points"
                }
              ],
              permissions: [permissions.readPlatformPoints],
              pagePermissions: {
                read: permissions.readPlatformPages,
                edit: permissions.editPlatformPoints
              }
            }
          },
          {
            path: "content",
            name: "points-content",

            component: () => import("@/views/platform/Page.vue"),
            meta: {
              middleware: middleware,
              title: "points.tie_cases",
              breadcrumbs: [
                {
                  path: "platform",
                  title: "sidebar.app_content"
                },
                {
                  path: "points",
                  title: "platform.points"
                },
                {
                  disabled: true,
                  path: "points",
                  title: "points.tie_cases"
                }
              ],
              permissions: [permissions.readPlatformPages],
              pagePermissions: {
                edit: permissions.editPlatformPages
              },
              pageKey: "points",
              editTitle: false
            }
          }
        ]
      },
      {
        path: "app-explanation",
        name: "app-explanation",
        component: () => import("@/views/platform/Page.vue"),
        meta: {
          middleware: middleware,
          title: "platform.app_explanation",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content"
            },
            {
              disabled: true,
              path: "app-explanation",
              title: "platform.app_explanation"
            }
          ],
          permissions: [permissions.readPlatformPages],
          pagePermissions: {
            edit: permissions.editPlatformPages
          },
          pageKey: "app-explanation",
          editTitle: false
        }
      },
      {
        path: "subscription",
        name: "subscription",
        component: () => import("@/views/platform/Subscription.vue"),
        meta: {
          middleware: middleware,
          title: "platform.subscription",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content"
            },
            {
              disabled: true,
              path: "subscription",
              title: "platform.subscription"
            }
          ],
          permissions: [permissions.readSubscriptions],
          pagePermissions: {
            edit: permissions.editSubscriptions
          }
        }
      },
      {
        path: "social-media",
        name: "social-media",
        component: () => import("@/views/platform/SocialMedia.vue"),
        meta: {
          middleware: middleware,
          title: "platform.social_media",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content"
            },
            {
              disabled: true,
              path: "social-media",
              title: "platform.social_media"
            }
          ],
          permissions: [permissions.readPlatform],
          pagePermissions: {
            edit: permissions.editPlatform
          }
        }
      },
      {
        path: "prizes",
        name: "prizes",
        component: () => import("@/views/platform/Page.vue"),
        meta: {
          middleware: middleware,
          title: "platform.prizes",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content"
            },
            {
              disabled: true,
              path: "prizes",
              title: "platform.prizes"
            }
          ],
          permissions: [permissions.readPlatformPages],
          pagePermissions: {
            edit: permissions.editPlatformPages
          },
          pageKey: "prizes",
          editTitle: true
        }
      },
      {
        path: "terms-and-conditions",
        name: "terms-and-conditions",
        component: () => import("@/views/platform/Page.vue"),
        meta: {
          middleware: middleware,
          title: "platform.terms_and_conditions",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content"
            },
            {
              disabled: true,
              path: "terms-and-conditions",
              title: "platform.terms_and_conditions"
            }
          ],
          permissions: [permissions.readPlatformPages],
          pagePermissions: {
            edit: permissions.editPlatformPages
          },
          pageKey: "terms-and-conditions",
          editTitle: false
        }
      },
      {
        path: "our-league",
        name: "our-league",
        component: () => import("@/views/platform/Page.vue"),
        meta: {
          middleware: middleware,
          title: "platform.our_league",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content"
            },
            {
              disabled: true,
              path: "our-league",
              title: "platform.our_league"
            }
          ],
          permissions: [permissions.readPlatformPages],
          pagePermissions: {
            edit: permissions.editPlatformPages
          },
          pageKey: "our-league",
          editTitle: true
        }
      },

      {
        path: "content",
        name: "platform-content",
        component: () => import("@/views/platform/Content.vue"),
        meta: {
          middleware: middleware,
          title: "platform.about_platform",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content"
            },
            {
              disabled: true,
              path: "platform-content",
              title: "platform.about_platform"
            }
          ],
          permissions: [permissions.readPlatform],
          pagePermissions: {
            edit: permissions.editPlatform
          }
        }
      },
      {
        path: "faq",
        name: "faq",
        component: () => import("@/views/platform/Faq.vue"),
        meta: {
          middleware: middleware,
          title: "platform.faq",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content"
            },
            {
              disabled: true,
              path: "faq",
              title: "platform.faq"
            }
          ],
          permissions: [permissions.readPlatformFaq],
          pagePermissions: {
            create: permissions.createPlatformFaq,
            edit: permissions.editPlatformFaq,
            delete: permissions.deletePlatformFaq
          }
        }
      },
      {
        path: "playing-rules",
        name: "playing-rules",
        component: () => import("@/views/platform/Page.vue"),
        meta: {
          middleware: middleware,
          title: "platform.playing_rules",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content"
            },
            {
              disabled: true,
              path: "playing-rules",
              title: "platform.playing_rules"
            }
          ],
          permissions: [permissions.readPlatformPages],
          pagePermissions: {
            edit: permissions.editPlatformPages
          },
          pageKey: "playing-rules",
          editTitle: true
        }
      },
      {
        path: "privacy-policy",
        name: "privacy-policy",
        component: () => import("@/views/platform/Page.vue"),
        meta: {
          middleware: middleware,
          title: "platform.privacy_policy",
          breadcrumbs: [
            {
              path: "platform",
              title: "sidebar.app_content"
            },
            {
              disabled: true,
              path: "privacy-policy",
              title: "platform.privacy_policy"
            }
          ],
          permissions: [permissions.readPlatformPages],
          pagePermissions: {
            edit: permissions.editPlatformPages
          },
          pageKey: "privacy-policy",
          editTitle: false
        }
      }
    ]
  },
  {
    path: "settings",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      }
    },
    children: [
      {
        path: "",
        name: "settings",
        component: () => import("@/views/settings/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.settings",
          breadcrumbs: [
            {
              disabled: true,
              path: "settings",
              title: "sidebar.settings"
            }
          ]
        }
      },

      {
        path: "sys-users",

        component: {
          render() {
            return h(resolveComponent("router-view"));
          }
        },
        children: [
          {
            path: "",
            name: "sys-users",
            component: () => import("@/views/system-users/index.vue"),
            meta: {
              middleware: middleware,
              title: "sidebar.sys-users",
              breadcrumbs: [
                {
                  path: "settings",
                  title: "sidebar.settings"
                },
                {
                  disabled: true,
                  path: "sys-users",
                  title: "sidebar.sys-users"
                }
              ],
              permissions: [permissions.readAdminUsers],
              pagePermissions: {
                logs: permissions.readLogs,
                create: permissions.addAdminUsers,
                edit: permissions.editAdminUsers,
                read: permissions.readRoles,
                reset: permissions.resetPasswordAdminUsers,
                delete: permissions.deleteAdminUsers
              }
            }
          },
          {
            path: "logs",
            name: "sys-users-logs",
            component: () => import("@/views/system-users/Logs.vue"),
            meta: {
              middleware: middleware,
              title: "sys-users.logs",
              breadcrumbs: [
                {
                  path: "settings",
                  title: "sidebar.settings"
                },
                {
                  path: "sys-users",
                  title: "sidebar.sys-users"
                },
                {
                  disabled: true,
                  path: "sys-users-logs",
                  title: "sys-users.logs"
                }
              ],
              permissions: [permissions.readLogs]
            }
          },
          {
            path: "sys-roles",
            component: {
              render() {
                return h(resolveComponent("router-view"));
              }
            },
            children: [
              {
                path: "",
                name: "sys-roles",
                component: () => import("@/views/system-roles/index.vue"),
                meta: {
                  middleware: middleware,
                  title: "sidebar.sys-roles",
                  breadcrumbs: [
                    {
                      path: "settings",
                      title: "sidebar.settings"
                    },
                    {
                      path: "sys-users",
                      title: "sidebar.sys-users"
                    },
                    {
                      disabled: true,
                      path: "sys-roles",
                      title: "sidebar.sys-roles"
                    }
                  ],
                  permissions: [permissions.readRoles],
                  pagePermissions: {
                    edit: permissions.editRoles,
                    read: permissions.readRoles,
                    delete: permissions.deleteRoles,
                    create: permissions.addRoles
                  }
                }
              },
              {
                path: "create",
                name: "sys-roles-create",
                component: () => import("@/views/system-roles/create.vue"),
                meta: {
                  middleware: middleware,
                  title: "sys-roles.create_new",
                  breadcrumbs: [
                    {
                      path: "settings",
                      title: "sidebar.settings"
                    },
                    {
                      path: "sys-users",
                      title: "sidebar.sys-users"
                    },
                    {
                      path: "sys-roles",
                      title: "sidebar.sys-roles"
                    },
                    {
                      disabled: true,
                      path: "sys-roles-create",
                      title: "sys-roles.create_new"
                    }
                  ],
                  permissions: [permissions.addRoles]
                }
              },
              {
                path: ":id",
                name: "sys-roles-details",
                component: () => import("@/views/system-roles/update.vue"),
                meta: {
                  middleware: middleware,
                  title: "sys-roles.update_role",
                  breadcrumbs: [
                    {
                      path: "settings",
                      title: "sidebar.settings"
                    },
                    {
                      path: "sys-users",
                      title: "sidebar.sys-users"
                    },
                    {
                      path: "sys-roles",
                      title: "sidebar.sys-roles"
                    },
                    {
                      disabled: true,
                      path: "sys-roles-details",
                      title: "sys-roles.update_role"
                    }
                  ],
                  permissions: [permissions.editRoles],
                  pagePermissions: {
                    delete: permissions.deleteRoles
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: "profile",
        name: "profile",
        component: () => import("@/views/user/index.vue"),
        meta: {
          middleware: middleware,
          title: "settings.profile",
          breadcrumbs: [
            {
              path: "settings",
              title: "sidebar.settings"
            },
            {
              disabled: true,
              path: "profile",
              title: "settings.profile"
            }
          ]
        }
      },
      {
        path: "shields",
        name: "shields",
        component: () => import("@/views/shields/index.vue"),
        meta: {
          middleware: middleware,
          title: "settings.shield_settings",
          breadcrumbs: [
            {
              path: "settings",
              title: "sidebar.settings"
            },
            {
              disabled: true,
              path: "shields",
              title: "settings.shield_settings"
            }
          ],
          permissions: [permissions.readShields],
          pagePermissions: {
            create: permissions.addShields,
            delete: permissions.deleteShields,
            read: permissions.readShields,
            edit: permissions.editShields
          }
        }
      },
    ]
  },
  ...sessionsRoutes,
  ...locationsRoutes
];
