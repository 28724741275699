{
  "header": {
    "notifications": "Notifications",
    "show_all_notifications": "View All Notifications",
    "change_language": "Change Language"
  },
  "sidebar": {
    "locations": "Locations",
    "sessions": "Sessions",
    "home": "Home",
    "users": "Users",
    "notifications": "Notifications",
    "settings": "Settings",
    "sys-roles": "System Roles",
    "sys-users": "System Users",
    "user_profile": "Profile"
  },
  "footer": {
    "all_rights_reserved": "All Right Reserved for",
    "platform": "Rabbe",
    "made_by": "Made in",
    "wameed": "Wameed"
  }
}