<script setup>
import { useI18n } from "vue-i18n";
import navMenuItems from "@/navigation";
import { ref } from "vue";
import { useUserStore } from "../../stores/user";
const shallShadowBottom = ref(false);
const userStore = useUserStore();
const { locale } = useI18n();
const navLinkProps = (item) => {
  const props = {};
  // If route is string => it assumes => Create route object from route name
  // If route is not string => It assumes it's route object => returns route object
  if (item.route)
    props.to = {
      name: item.route,
      params: { lang: locale.value },
    };
  else {
    props.href = item.href;
    props.target = "_blank";
    props.rel = "nofollow";
  }

  if (!props.target) props.target = item.target || null;

  return props;
};
</script>
<template>
  <div
    class="main-menu-content scroll-area d-flex flex-column justify-content-between"
   
  >
    <ul
      v-if="userStore.profile?.name"
      class="navigation navigation-main mt-md-5"
    >
      <template v-for="item in navMenuItems" :key="item.header || item.title">
        <li
          v-permission="item.permissions"
          v-if="item.header"
          class="navigation-header text-truncate"
        >
          <span>{{ $t("sidebar." + item.header) }}</span>
        </li>
        <li
          v-permission="item.permissions"
          v-if="item.title"
          :class="`nav-item ${item.title} active ${
            item.disabled ? 'disabled' : ''
          }`"
        >
          <b-link
            v-bind="navLinkProps(item)"
            :class="`d-flex align-items-center ${item.title}`"
          >
            <component v-bind:is="item.icon" :class="item.icon"></component>
            <span class="menu-title text-truncate text-medium-18">{{
              $t("sidebar." + item.title)
            }}</span>
            <b-badge
              v-if="item.tag"
              pill
              :variant="item.tagVariant || 'primary'"
              class="mr-1 ml-auto"
            >
              {{ item.tag }}
            </b-badge>
          </b-link>
        </li>
      </template>
    </ul>
  </div>
</template>
