{
  "login": {
    "title": "Welcome \uD83D\uDC4B\uD83C\uDFFB",
    "subtitle": "Enter your email and password to login",
    "forgot_password": "Do not remember password?",
    "login": "Login"
  },
  "forgot_password": {
    "title": "Forgot password",
    "subtitle": "Please enter your email address",
    "login_page": "login page",
    "or ": "or"
  },
  "reset_code": {
    "title": "Reset password code",
    "subtitle": "Please enter the code sent to your email",
    "nothing_received": "nothing received"
  },
  "reset_password": {
    "title": "Reset password",
    "subtitle": "Please enter the new password for your account"
  }
}
