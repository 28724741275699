{
  "settings": {
    "main_settings": "Basic Settings",
    "main_settings_subtitle": "Platform data, social media",
    "system_users": "System Users",
    "system_users_subtitle": "users, permissions, user log",
    "profile": "Profile",
    "profile_subtitle": "Modify your profile",
    "password": "Password",
    "advance_setting": "Advanced Settings",
    "language": "Language",
    "personal_information": "Personal Data",
    "notifications_setting": "Notification Sound",
    "welcome_user": "Welcome",
    "update_personal_information": "Modify personal data",
    "change_password": "modify password",
    "reset_password": "Reset password"
  },
  "platform": {
    "points": "points calculation mechanism",
    "points_subtitle": "Points calculation mechanism data",
    "app_explanation": "application explanation",
    "app_explanation_subtitle": "Explanation of application interfaces",
    "subscription": "Premium subscription",
    "subscription_subtitle": "Edit your personal data",
    "social_media": "Follow us on social media platforms",
    "social_media_subtitle": "Social media numbers and links",
    "prizes": "Prizes",
    "prizes_subtitle": "Prizes data",
    "terms_and_conditions": "Terms and Conditions",
    "terms_and_conditions_subtitle": "Content of terms and conditions",
    "our_league": "About our league",
    "our_league_subtitle": "Information about our league",
    "about_platform": "about the platform",
    "about_platform_subtitle": "Editing your personal data",
    "faq": "Frequently Asked Questions",
    "faq_subtitle": "Frequently Asked Questions Control",
    "playing_rules": "Playing rules",
    "playing_rules_subtitle": "Playing rules Information",
    "privacy_policy": "Privacy Policy",
    "privacy_policy_subtitle": "Privacy Policy Content",
    "hide": "Hide Page",
    "show": "Show Page"
  },
  "points": {
    "title": "Points Calculation Mechanism",
    "tie_cases": "Tie Cases",
    "table": {
      "name": "Prediction status",
      "normal_match_points": "Normal match",
      "fire_match_points": "Fire match",
      "actions": "Actions"
    }
  },
  "faq": {
    "title": "FAQ",
    "create_new": "Add a new question",
    "details": "Question Details",
    "table": {
      "question": "Question",
      "answer": "answer",
      "created_at": "Created Date",
      "Status": "Status",
      "actions": "Actions"
    }
  },
  "advertisements": {
    "create_new": "Create New Ad",
    "table": {
      "profile": "Advertisement",
      "created_at": "Created date",
      "status": "Status",
      "actions": "Actions"
    },
    "table_actions": {
      "details": "Advertisement details",
      "delete": "Delete Ad"
    }
  },
  "notifications": {
    "send_new_notification": "Add a new notification",
    "send": "send notification",
    "table": {
      "name": "Notification name",
      "content": "Notification content",
      "created_at": "Created Date",
      "target": "Target group",
      "actions": "Actions"
    },
    "table_actions": {
      "resend": "resend"
    },
    "users": "Users",
    "targets": "target group",
    "add_target": "Add target group",
    "user": "Users",
    "all_users": "All Users",
    "specific_user": "Specific users",
    "winners": "Winners",
    "by_age": "by Age",
    "gender": "Gender",
    "all": "All",
    "specific": "Custom",
    "male_only": "Male only",
    "female_only": "Women only",
    "specific_age": "Age",
    "from_to": "from--to--",
    "select_all": "Select All",
    "date_error": "The start field must be greater than the end field."
  },
  "sys-roles": {
    "create_new": "Add a new Role",
    "update_role": "Modify Role",
    "delete": "Delete",
    "table": {
      "name": "Role Name",
      "created_at": "Created date",
      "status": "Status",
      "actions": "Actions"
    },
    "table_actions": {
      "view": "View details"
    }
  },
  "permissions": {
    "sections": {
      "dashboard": "Dashboard",
      "sessions": "Sessions",
      "locations": "Locations",
      "users": "Users",
      "notifications": "Notifications",
      "admin_users": "System Users",
      "roles": "Roles",
      "user_profile": "Profile Settings"
    },
    "read_dashboard": "View Dashboard",
    "read_users": "View Users",
    "read_user_details": "View User Details",
    "toggle_users": "Activate/Deactivate Users",
    "assign_shields": "Assign Shields",
    "edit_users": "Edit Users",
    "delete_users": "Delete Users",
    "read_notifications": "View Notifications",
    "send_notifications": "Send Notifications",
    "delete_notifications": "Delete Notifications",
    "read_platform": "View Platform Information",
    "edit_platform": "Edit Platform Information",
    "read_platform_pages": "View Platform Content",
    "edit_platform_pages": "Edit Platform Content",
    "read_platform_faq": "View FAQ",
    "add_platform_faq": "Add FAQ",
    "edit_platform_faq": "Edit FAQ",
    "delete_platform_faq": "Delete FAQ",
    "read_platform_points": "View Points Calculation Method",
    "edit_platform_points": "Edit Points Calculation Method",
    "read_admin_users": "View System Users",
    "add_admin_users": "Add System Users",
    "edit_admin_users": "Edit System Users",
    "delete_admin_users": "Delete System Users",
    "read_admin_users_logs": "View System Users Log",
    "reset_password_admin_users": "Reset System Users Password",
    "read_roles": "View Roles",
    "add_roles": "Add Roles",
    "edit_roles": "Edit Roles",
    "delete_roles": "Delete Roles",
    "read_sessions": "View Sessions",
    "read_session_details": "View Session Details",
    "add_locations": "Add Locations",
    "edit_locations": "Edit Locations",
    "delete_locations": "Delete Locations",
    "read_locations": "View Locations",
    "read_location_details": "View Location Details",
    "toggle_locations": "Activate/Deactivate Locations"
  },
  "sys-users": {
    "create_new": "Add a new user",
    "view_logs": "View Logs",
    "roles_management": "Role Management",
    "logs": "Logs",
    "resend_password": "Resend Password",
    "delete": "Delete the user",
    "table": {
      "name": "User",
      "role": "Role",
      "Last Activity": "Last Activity",
      "status": "Status",
      "actions": "Actions"
    },
    "table_actions": {
      "View": "View Details"
    }
  },
  "logs": {
    "table": {
      "user": "User name",
      "title": "Process Title",
      "content": "Process Details",
      "created_date_time": "Time and Date"
    }
  },
  "dashboard": {
    "statistics": {
      "title": "statistics",
      "total_users": "total users",
      "total_locations": "total locations",
      "total_suggested_locations": "total suggested locations",
      "total_sessions": "total sessions"
    },
    "last_7_days": "last 7 days",
    "last_30_days": "last 30 days",
    "last_12_months": "last 12 months",
    "males": "Males",
    "females": "Females",
    "point": "point",
    "request": "request",
    "resent_users": "most recent users",
    "user": "User",
    "ended_matches": "Ended matches awaiting results",
    "total_users_by_country": "Users Statistics",
    "users_gender": "Users Gender",
    "user_joining_avarage": "Joining User Rate"
  },
  "teams": {
    "create_new": "Add New Team",
    "update": "Update Data",
    "details": "Team Details",
    "table": {
      "profile": "team",
      "championships": "Championships",
      "created_at": "Created Date",
      "actions": "Actions"
    }
  },
  "championships": {
    "create_new": "Add New Championship",
    "update": "Update Data",
    "details": "Championship Details",
    "total_matches": "Total Matches",
    "table": {
      "profile": "Championship",
      "total_teams": "Total Teams",
      "start_date": "Start Date",
      "end_date": "End Date",
      "created_at": "Created Date",
      "actions": "Actions"
    }
  },
  "matches": {
    "create_new": "Add New Match",
    "update": "Modify data",
    "details": "Match Details",
    "fire": "Fire",
    "normal": "Normal",
    "table": {
      "team_a": "First Team",
      "team_b": "Second team",
      "score": "Score",
      "match_date": "Mastch Date",
      "match_type": "Mastch Type",
      "match_result": "Status",
      "actions": "Actions"
    },
    "disabled": "Disabled",
    "waiting_for_results": "Wating For Results",
    "finished": "Completed",
    "on_going": "On Going",
    "total_predictions": "Total Predictions",
    "championship": "Championship",
    "date_time": "Time and date"
  },
  "championship_teams": {
    "table": {
      "profile": "Team",
      "total_matches": "Total Matches",
      "created_at": "Created Date",
      "actions": "Actions"
    }
  },
  "predictions": {
    "table": {
      "profile": "User Name",
      "score": "Prediction",
      "created_at": "Date",
      "prediction_result": "Prediction result",
      "actions": "Actions"
    },
    "prediction_results": {
      "0": "-",
      "1": "Less than 10%",
      "2": "Correct prediction",
      "3": "Predict correct win.",
      "4": "Predict number of goals",
      "5": "Predict correct difference",
      "6": "Wrong prediction",
      "7": "Predict number of goals and correct win."
    },
    "statistics": {
      "correct": "Correct predictions",
      "wrong": "Wrong predictions",
      "winner": "Win and draw predictions",
      "difference": "Difference prediction",
      "close": "Number of goals Predictions",
      "less_10_percentage": "Hat-trick Predictions"
    }
  },
  "winners-periods": {
    "create_new": "Add Winners",
    "details": "Winning Period Details",
    "table": {
      "period_type": "Winners Type",
      "name": "Period name",
      "period": "period",
      "total_winners": "Total Winners",
      "actions": "Actions"
    },
    "period_type": {
      "1": "Weekly",
      "2": "monthly",
      "3": "Seasonal"
    },
    "period_types": {
      "1": "weekly",
      "2": "monthly",
      "3": "Seasonal"
    },
    "choose_winners": "Choose Winners",
    "weekly_points": "Weekly Points:",
    "monthly_points": "monthly points: ",
    "yearly_points": "Yearly Points:",
    "start_date": "start date",
    "end_date": "End date",
    "total_points": "Points"
  },
  "user-predictions": {
    "table": {
      "match": "Match",
      "score": "Prediction",
      "created_at": "Date",
      "prediction_result": "Prediction Result",
      "actions": "Actions"
    },
    "prediction_results": {
      "0": "-",
      "1": "Less than 10%",
      "2": "Correct prediction",
      "3": "Predict  correct win.",
      "4": "Predict Number of goals",
      "5": "Predict correct difference",
      "6": "Wrong prediction",
      "7": "Predict number of goals and correct win."
    },
    "statistics": {
      "correct": "Correct predictions",
      "wrong": "Wrong predictions",
      "winner": "Win and draw predictions",
      "difference": "Difference prediction",
      "close": "Number of goals Predictions",
      "less_10_percentage": "Hat-trick Predictions"
    }
  },
  "winners": {
    "details": "winner details",
    "table": {
      "profile": "Winner",
      "order_num": "Order",
      "points": "Points",
      "actions": "Actions"
    }
  },
  "groups": {
    "details": "league details",
    "table": {
      "profile": "league",
      "total_members": "Total Members",
      "username": "League Owner",
      "championships": "Championships",
      "created_at": "Created Date",
      "status": "Status",
      "actions": "Actions"
    },
    "total_championships": "Total Championships"
  },
  "members": {
    "title": "Members",
    "details": "User details",
    "table": {
      "profile": "user",
      "total_points": "Points",
      "total_predictions": "Total Predictions",
      "created_at": "Join date",
      "actions": "Actions"
    }
  },
  "group-championships": {
    "table": {
      "profile": "Championship",
      "total_teams": "Total Teams",
      "created_at": "Created Date",
      "actions": "Actions"
    }
  },
  "google_ads": {
    "type": {
      "1": "Number of Expectations Ads",
      "2": "Between Elements Ads",
      "3": "Page Footer Ads",
      "4": "Page Navigation Ads",
      "5": "Banner Ads"
    }
  },
  "subscription-products": {
    "table": {
      "product_id": "Subscription ID",
      "product_type": "Subscription Type",
      "product_period": "Duration",
      "status": "Status",
      "actions": "Actions"
    },
    "product_type": {
      "1": "Monthly",
      "2": "Annual"
    },
    "product_period": {
      "1": "month",
      "2": "years"
    },
    "total_championships": "Number of Championships"
  },
  "subscribers": {
    "details": "subscriber details",
    "table": {
      "profile": "user",
      "product_type": "Subscription type",
      "provider": "Store",
      "receipt_status": "Status in Store",
      "start_date": "Subscription Date",
      "expire_date": "Subscription Expiry Date",
      "status": "Subscription Status",
      "actions": "Actions"
    },
    "receipt_status": {
      "1": "Subscribed",
      "2": "Finished",
      "3": "Cancelled"
    },
    "provider": {
      "google": "Google",
      "apple": "apple"
    },
    "product_type": {
      "1": "monthly",
      "2": "annual"
    },
    "product_period": {
      "1": "month",
      "2": "years"
    },
    "subscription_details": "Subscription Information"
  }
}