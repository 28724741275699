import { permissions } from "@/utils/permissions-enum";

export default [
  {
    title: "home",
    icon: "home-icon",
    route: "home",
    permissions: []
  },

  {
    title: "users",
    icon: "users-icon",
    route: "users",
    permissions: [permissions.readUsersInfo]

  },

  {
    title: "sessions",
    icon: "sessions-icon",
    route: "sessions",
    permissions: [permissions.readSessions]

  },
  {
    title: "locations",
    icon: "locations-icon",
    route: "locations",
    permissions: [permissions.readLocations]

  },
  {
    title: "notifications",
    icon: "notifications-icon",
    route: "notifications",
    permissions: [permissions.readNotifications]
  },
  {
    title: "settings",
    icon: "settings-icon",
    route: "settings",
    permissions: []
  }
];
