import { h, resolveComponent } from "vue";
import { permissions } from "@/utils/permissions-enum";
import middleware from "../middleware/middleware";

export default [
  {
    path: "sessions",

    component: {
      render() {
        return h(resolveComponent("router-view"));
      }
    },
    children: [
      {
        path: "",
        name: "sessions",
        component: () => import("@/views/sessions/index.vue"),
        meta: {
          middleware: middleware,
          title: "sidebar.sessions",
          breadcrumbs: [
            {
              path: "sessions",
              title: "sidebar.sessions",
              disabled: true
            }
          ],
          permissions: [permissions.readSessions],
          pagePermissions: {
            read: permissions.readSessionDetails
          }
        }
      },
      {
        path: ":id",
        name: "sessions-details",
        component: () => import("@/views/sessions/details.vue"),
        meta: {
          middleware: middleware,
          title: "sessions.details",
          breadcrumbs: [
            {
              path: "sessions",
              title: "sidebar.sessions"
            },
            {
              path: "sessions-details",
              title: "sessions.details",
              disabled: true
            }
          ],
          permissions: [permissions.readUserDetails],
          pagePermissions: {
            read: permissions.readUserDetails
          }
        }
      }
    ]
  }
];
