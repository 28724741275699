import users from "./users.json";
import shields from "./shields.json";
import locations from "./locations.json";

const serviceEn = {
  ...users,
  ...shields,
  ...locations,
};
export default serviceEn;
