{
  "users": {
    "update": "Update Information",
    "details": "User Details",
    "table": {
      "name": "User",
      "rank": "Rank",
      "points": "Experience Points",
      "phone": "Phone Number",
      "actions": "Actions"
    },
    "created_at": "Creation Date",
    "shields": "Shields",
    "sessions": "Sessions",
    "assign-shield": "Assign Shield",
    "email": "Email",
    "gender": "Gender",
    "f": "Female",
    "m": "Male",
    "birth_date": "Birth Date",
    "points": "Points",
    "locations": "User Locations"
  }
}