{
  "sessions": {
    "details": "تفاصيل الجلسة",
    "players": "اللاعبين",
    "reviews": "التقييمات",
    "games": "الصكات",
    "show_score": " عرض لوحة النتائج",
    "score_board": "لوحة نتائج لعبة رقم ",
    "round": "الصكة",
    "created_at": "تاريخ الانشاء",
    "team_a": "فريق أ",
    "team_b": "فريق ب",
    "game": "اللعبة رقم ",
    "location": "موقع الجلسة",
    "table": {
      "name": "اسم الجلسة",
      "created_user": "راعي الجلسة",
      "start_time": "تاريخ ووقت الجلسة",
      "location": "الموقع",
      "session_status": "الحالة",
      "actions": "الاجراءات"
    },
    "status": {
      "pending": "قيد الانتظار",
      "active": "نشطة",
      "completed": "مكتملة"
    },
    "number": {
      "1": "الأولى",
      "2": "الثانية",
      "3": "الثالثة",
      "4": "الرابعة",
      "5": "الخامسة",
      "6": "السادسة",
      "7": "السابعة",
      "8": "الثامنة",
      "9": "التاسعة",
      "10": "العاشرة"
    }
  }
}