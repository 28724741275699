import { defineStore } from "pinia";
// import events from '@/fake'
export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    notifications: [],
    profile: {
      permissions: null
    }
  }),
  getters: {
    getNotifications: (state) => state.notifications,
    getPermissions: (state) => state.profile?.permissions
  },
  actions: {
    async _getProfile() {
      this.$wameedLoading("loading-overly", true);
      try {
        const response = await this.$http.get("admin/profile");
        this.profile = response.data.data;
        console.log(this.profile);
      } catch (err) {
        throw err;
      } finally {
        this.$wameedLoading("loading-overly", false);
      }
    },
    async _updateProfile(data) {
      this.$wameedLoading("profile-update", true);
      try {
        const response = await this.$http.patch("admin/profile", data);
        this.profile = response.data.data;
        this.$wameedNotification({
          title: "information_saved",
          type: "success"
        });
      } catch (err) {
        throw err;
      } finally {
        this.$wameedLoading("profile-update", false);
      }
    },
    async _resetPassword(data) {
      this.$wameedLoading("change-password", true);
      try {
        const response = await this.$http.patch("admin/profile/change-password", data);
        this.$wameedNotification({
          title: "password_changed",
          type: "success"
        });
        return true;
      } catch (err) {
        throw err;
      } finally {
        this.$wameedLoading("change-password", false);
      }
    },

    async loadNotifications() {
      this.$wameedLoading("loading-overly", true);
      try {
        const response = await this.$http.get("admin/profile/notifications");
        this.notifications = response.data.data;
        return true;
      } catch (err) {
        throw err;
      } finally {
        this.$wameedLoading("loading-overly", false);
      }
    },
    async readNotifications(data) {
      this.$wameedLoading("loading-overly", true);
      try {
        await this.$http.post("admin/profile/notifications/read", data);
        this.profile.unread_notifications = 0;
      } catch (err) {
        throw err;
      } finally {
        this.$wameedLoading("loading-overly", false);
      }
    }
  }
});
